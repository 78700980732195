.video-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 44%;
    z-index: 1;
}

.video-container-full {
    width: 64%;
    z-index: 999;
    top: 15%;
    right: 19%;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    animation-name: videoZoomIn;
    animation-duration: 0.5s;
}

@keyframes videoZoomIn {
    from {
        position: fixed;
        top: 13vw;
        right: 0;
        width: 44%;
        z-index: 1;
    }
    to {
        position: fixed;
        top: 15%;
        right: 19%;
        width: 64%;
        z-index: 999;
    }
}

@media screen and (max-width: 600px) {
    .video-container {
        position: relative;
        display: block;
        width: 100%;
    }
}
