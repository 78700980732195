.body-home {
    padding: 53px;
}

.logo {
    font-size: 4vw;
    font-weight: 400;
}

.big-pad-right {
    padding-right: 120px;
}

.width65 {
    width: 65%;
}

@media screen and (max-width: 600px) {
    .width65,
    .width90 {
        width: 100%;
    }
    .body-home {
        padding: 50px 16px;
    }
    .pad-mob {
        padding-top: 7px;
    }
    .logo {
        font-size: 3.3rem;
    }
    .home-media-box-mob {
        padding: 0px 0px 40px;
    }
}
