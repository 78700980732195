.navbar {
    width: calc(100vw - 115px);
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    padding: 52px 54px;
    background-color: transparent;
    z-index: 999;
}

.side-nav {
    display: flex;
    /* justify-content: flex-end; */
    font-size: 2.5vw;
    padding-top: 4px;
    font-weight: 300;
}

.nav-container {
    /* height: 10vh;
    padding-bottom: 38px; */
}

.nav-link {
    cursor: pointer;
    color: #eaeaea;
}

.nav-link:hover {
    color: #959595;
}

@media screen and (max-width: 600px) {
    .sec-nav-link {
        font-size: 1.3rem;
    }
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 40px 15px;
        width: calc(100vw - 37px);
    }
}
