@font-face {
  font-family: 'Graphik';
  font-weight: 350;
  src: local('GraphikRegular'), url(./fonts/Graphik/GraphikRegular.otf) format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 600;
  src: local('Graphik'), url(./fonts/Graphik/GraphikBold.otf) format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 500;
  src: local('Graphik'), url(./fonts/Graphik/GraphikSemibold.otf) format('opentype');
}
@font-face {
  font-family: 'Graphik';
  font-weight: 400;
  src: local('Graphik'), url(./fonts/Graphik/GraphikMedium.otf) format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 300;
  src: local('Graphik'), url(./fonts/Graphik/GraphikLight.otf) format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 200;
  src: local('Graphik'), url(./fonts/Graphik/GraphikThin.otf) format('opentype');
}

body {
  margin: 0;
  background-color: black;
  font-family: 'Graphik', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
