.border-top-bottom {
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    padding-top: 13px;
    padding-bottom: 13px;
    /* width: 220px; */
    font-size: 1.67vw;
    letter-spacing: 0.08vw;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 1.6vw;
}

.list-container {
    color: #959595;
    font-weight: 300;
    font-size: 1.67vw;
    letter-spacing: 0.64px;
    padding: 20px 0px;
}

.list-item {
    margin: 0.85vw 0px;
}

.list {
    width: 27%;
}

@media screen and (max-width: 640px) {
    .border-top-bottom {
        width: 170px;
        font-size: 3.5vw;
        color: #ffffff;
        font-weight: 300;
        padding-bottom: 9px;
        padding-top: 9px;
    }

    .list {
        padding-right: 60px;
        width: 70%;
    }

    .list:last-child {
        padding-right: 0px;
    }

    .list-container {
        font-size: 3.4vw;
    }
    .list-item {
        margin: 2.3vw 0px;
    }
}
