.about-container {
    display: flex;
    margin-bottom: 100px;
}

.image-about {
    width: 100%;
}

.list-outer-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
}

.links-rows {
    width: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #707070;
    font-weight: 500;
    font-size: 1.3vw;
    text-decoration-color: transparent;
}

.links-rows:last-child {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    font-weight: 400;
}

.lists-container {
    display: flex;
    justify-content: space-between;
    width: 93%;
    padding: 70px 0px;
}

.link {
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.link:hover {
    color: #959595;
}

.padTop10 {
    padding-top: 10px;
}

.left-block {
    width: 53%;
}

.right-block {
    width: 47%;
}

.top-footer {
    width: 100%;
    display: flex;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    padding-top: 13px;
    padding-bottom: 13px;
}

.footer-text {
    font-size: 2.5vw;
    font-weight: 300;
    padding-bottom: 10px;
}

.footer-container {
    display: flex;
    justify-content: flex-end;
}

.stamp-container {
    position: sticky;
    top: 650px;
    right: 0;
}

.stamp-box {
    position: relative;
}

.email-me-box {
    width: 72%;
}

.big-text-about {
    font-size: 3.9vw;
    font-weight: 350;
    letter-spacing: 1.48px;
    margin-top: 0px;
    margin-bottom: 45px;
    line-height: 4.9vw;
}

.image-rotation,
.fixed-image-rotation {
    position: absolute;
    right: 0px;
    width: 140px;
    /* bottom: -80px; */
    -webkit-animation: spin 8s linear infinite;
    -moz-animation: spin 8s linear infinite;
    animation: spin 8s linear infinite;
}

.face {
    position: absolute;
    right: 33px;
    width: 75px;
    z-index: 999;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 600px) {
    .about-container {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 40px;
    }

    .top-footer {
        flex-direction: column;
        border-bottom: transparent;
    }

    .email-me-box {
        width: 100%;
        padding: 10px 0px;
    }

    .footer-text {
        font-size: 5vw;
        padding-bottom: 20px;
    }

    .portrait-container {
        padding-left: 0px;
    }

    .image-rotation {
        width: 80px;
        bottom: -90px;
        right: 10px;
    }

    .face {
        bottom: -70px;
        right: 30px;
        width: 40px;
    }

    .left-block,
    .right-block {
        width: 100%;
    }

    .left-block {
        margin-top: 10px;
    }

    .left-nav {
        padding: 53px 0px 0px 16px;
    }

    .links-rows {
        width: 100%;
        font-size: 4.5vw;
    }

    .list-outer-container {
        padding: 0px;
        display: block;
    }

    .footer-container {
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .lists-container {
        padding: 30px 0px;
        display: flex;
        overflow-x: auto;
        justify-content: space-between;
    }
    .big-text-about {
        font-size: 7.5vw;
        line-height: 40px;
        font-weight: 350;
        letter-spacing: 1.48px;
        margin-top: 30px;
    }
}
